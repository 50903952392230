import React, { Component } from 'react';
import Header from '../components/header'

export default class extends Component {
  
  render() {
    return (
      <>
        <Header/>
        {/* <section className="main-description">
          <div style={{ width: "90%", margin: "0 auto"}}>
            <h1>
              
            </h1>
            <p> 
              Welcome to Techianodo, here you will come to know various web technologies. Truly knowing
              different technologies is Power!
            </p>
            <button>
              CHECK OUT BLOGS
            </button>
          </div>
        </section>
        <section></section> */}
      </>
    );
  }
}